import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import * as icons from 'react-bootstrap-icons';
import RightSideBanner from './RightSideBanner';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdatePasswordFn, linkVerify } from '../../redux/auth-reducer/auth-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function UpdatePassword() {
  const parms = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const linkcheck = async () => {
      await linkVerify(parms.id).then((res) => {
        if (res?.status == true) {
        } else {
          toast.error("Link Exipired")
            navigate('/');
        }
      })
    }
    linkcheck();
  }, [])

  const [validated, setValidated] = useState(false);
  const [err, setErr] = useState("");
  let [visible, setVisible] = useState(false);
  let [visibleIcon, setVisibleIcon] = useState(false);
  const [addPassword, setAddPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const handelform = (e) => {
    setAddPassword({
      ...addPassword,
      [e.target.name]: e.target.value
    })
  }



  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    const data = {
      "password": addPassword?.password,
      "confirmPassword": addPassword?.confirmPassword,
      "token": parms.id
    }
    await UpdatePasswordFn(data).then((res) => {
      if (res?.status == true) {
        toast.success(res?.message);
          navigate('/Login');

      } else {
        toast.error(res?.message);
          navigate('/Login');
      }
    });

  };

  useEffect(() => {
    if (addPassword?.password !== addPassword?.confirmPassword && addPassword?.password?.length > 0 && addPassword?.confirmPassword?.length > 0) {
      setErr("Password and confirmPassword does not match")
    } else {
      setErr("")
    }
  }, [addPassword?.password, addPassword?.confirmPassword])
  const show = (event) => {
    setVisible(!visible)
  }
  const showIcon = () => {
    setVisibleIcon(!visibleIcon)
  }
  return (
    <>
      <div className='group-reg'>
        <div className='item'>
          <div className='inner-item'>
            <div className='form-contnet'>
              <h1 className='heading'>Update Password</h1>

            </div>
            <Form noValidate validated={validated} className='Login-form' onSubmit={handleSubmit}>
              <Row className="mb-3">


                <Form.Group as={Col} md="12" controlId="validationCustomFristname">
                  <Form.Label>New password</Form.Label>
                  <InputGroup hasValidation>


                    <Form.Control
                      type={visible ? "text" : "password"}
                      value={addPassword?.password}
                      onChange={handelform}
                      name="password"
                      placeholder="Enter new password"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <div className='eye-icon' onClick={show}>
                      {
                        visible ? <icons.Eye /> : <icons.EyeSlash />
                      }

                    </div>

                    <Form.Control.Feedback type="invalid">
                      Please Enter a New Password.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationCustomFristname">
                  <Form.Label>Confirm password</Form.Label>
                  <InputGroup hasValidation>


                    <Form.Control
                      type={visibleIcon ? "text" : "password"}
                      width={100}
                      onChange={handelform}
                      value={addPassword?.confirmPassword}
                      name="confirmPassword"
                      placeholder="Enter confirm password"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <div className='eye-icon' onClick={showIcon}>
                      {
                        visibleIcon ? <icons.Eye /> : <icons.EyeSlash />
                      }

                    </div>
                    {
                      err ? <p className='err-message'>Password and Confirm Password does not match</p> : <Form.Control.Feedback type="invalid">
                        Please Enter a Confirm Password
                      </Form.Control.Feedback>
                    }

                  </InputGroup>
                </Form.Group>

                <Col md="12 ">
                  <Button type="submit" className='submit'>Continue</Button>
                </Col>



              </Row>
            </Form>

          </div>
        </div>
        <RightSideBanner />
      </div>
    </>
  )
}
export default UpdatePassword;