import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import { Container } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { RemovRoles, RemoveRefreshToken, RemoveToken, RemoveUser, SetRefreshToken, SetToken } from '../../utils/helper/helper';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { ImageUrl, REFRESH_TOKEN_API, SignalRUrl } from '../../utils/config/config';
import * as signalR from '@microsoft/signalr'
import * as icons from "react-bootstrap-icons";

import { toast } from 'react-toastify';
import { ManagerGetById, PlayerGetById } from '../../redux/auth-reducer/auth-service';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment';
import { Context } from '../../redux/Context';
import { useDispatch } from 'react-redux';
import { setAuthToeken } from '../../redux/auth-reducer/auth-reducer';
import { Loader } from 'rsuite';
import { createLogger } from '@microsoft/signalr/dist/esm/Utils';


function TopNavbar({ sidebarfun }) {

    const roles = localStorage.getItem("role")
    const [userData, setUserData] = useState();
    const [showNotification, setShowNotification] = useState(false);
    const [showQuery, setShowQuery] = useState(false);
    const [signalRNotification, setSignalRNotification] = useState([])
    const [signalRQuery, setSignalRQuery] = useState([])
    const [notificationCount, setNotificationCount] = useState()
    const [queryCount, setQueryCount] = useState();
    const [auctionCreateData, setAuctionCreateData] = useState([]);
    const [count, setCount] = useState();
    const { hubConnection } = useContext(Context);
    const dispatch = useDispatch()

    useEffect(() => {
        if (localStorage.getItem("isToken")) {
            dispatch(setAuthToeken(localStorage.getItem("isToken")))
        }
    }, [() => localStorage.getItem("isToken")])


    const navigate = useNavigate();
    async function logout() {

        RemoveToken();
        RemoveRefreshToken();
        RemovRoles();
        localStorage.removeItem("details");
        localStorage.removeItem("isUser");
        localStorage.removeItem("img");
        localStorage.removeItem("checkoutValue");
        // localStorage.clear();
        navigate("/Home");

        localStorage.removeItem('sesstionID');


    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;
    const isRole = localStorage.getItem('role');
    const fullName = isUser?.fullName;



    useEffect(() => {

        if (!fullName || fullName.trim() === '') {
            RemoveToken();
            RemoveRefreshToken();
            RemoveUser();
            RemovRoles();

            navigate('/login'); // Navigate to login page
        }
    }, [fullName, navigate]);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.on("ReceiveNotification", (data) => {
                setSignalRNotification(data);

                setNotificationCount(data?.length)
            });

            hubConnection.on("ContactUs", (queryData) => {
                setSignalRQuery(queryData[0]);
                setQueryCount(queryData?.length)
            });

        }
    }, [hubConnection]);

    console.log(signalRNotification, "signar Data")
    useEffect(() => {
        if (hubConnection) {
            hubConnection.on("ReceiveAuctionNotification", (data) => {
                console.log(data, "auctionCreateData")

                setAuctionCreateData(data)


            });

        }
    }, [hubConnection]);

    console.log(auctionCreateData, "auctionCreateData1")




    const handleLandingPage = () => {
        navigate("/");
    }

    const handleQueryPage = () => {
        navigate("/WebAdmin/Query");
    }

    const handleNotificationClick = () => {
        setShowNotification(!showNotification)
     console.log(signalRNotification?.length,"dfsdsf")
    };

    /******************* */

    /*************************** */



    const handleQueryClick = () => {

        setShowQuery(!showQuery)
        console.log(auctionCreateData?.length,"dd")
    };

    const TokenInvalid = () => localStorage.getItem("TokenInvalid");
    useEffect(() => {
        let tokendata = TokenInvalid();
        if (tokendata == "true") {
            alert("Session has timeout. Please login");
            localStorage.removeItem("TokenInvalid");
            SetToken(null);
            SetRefreshToken(null);
   
            navigate("/login");
        }
    }, [TokenInvalid()]);




    const handleNotificationPageManager = () => {
        navigate("/SuperAdmin/SuperNotification");
    }

    const handleNotificationPagePlayer = () => {
        navigate("/Player/Notification");
    }
    {
        auctionCreateData?.map((item) => {
            var auctionInfo = item?.message;
            var auctionName = auctionInfo.split("auction")[0];
            var auctionmsg = auctionInfo.split("auction")[1];
        })
    }
    console.log(isUser?.fullName, " isUser?.fullName")

    return (
        <>
            <div className="topbar">
                <Container fluid>

                    <Row className='align-items-center '>
                        <Col xs={6} className=' mob'> <div className='logo-image'>
                            <Link to="/Home">
                                <img src="/image/logo1.png" width={115}></img>
                            </Link>

                        </div></Col>
                        <Col xs={6} className='text-right mob'><img src="../image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img></Col>

                        <Col md="6" className='desk'>
                            <div className='innetr-group p-2'>

                                <div className='group-top-bar ms-0'>
                                    <div className='logo-image'>
                                        <Link to="/Home">
                                            <img src="/image/logo1.png" width={115}></img>
                                        </Link>
                                    </div>
                                    <div className='icon-bar'>
                                        <img src="/image/minu-icon.svg" onClick={sidebarfun} className='icon-menue'></img>
                                    </div>

                                </div>
                            </div>
                        </Col>

                        <Col md="6">
                            <div className='d-flex p-2 align-items-center justify-content-end dropdown-section'>




                                <div className='me-3' onClick={handleLandingPage}><icons.HouseDoor className='home-icon-top'></icons.HouseDoor></div>
                             

                                {
                                    roles == "Web Admin" ?
                                        <Dropdown className='notification-message'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                <icons.Bell className='bell-notify' onClick={handleQueryClick} />
                                                {
                                                    signalRQuery?.length == 0 ? null : <span class="badge-notification" onClick={handleQueryClick}>

                                                        !
                                                    </span>
                                                }

                                            </Dropdown.Toggle>
                                            {
                                                signalRQuery?.length == 0 ? <Dropdown.Menu>
                                                    No Data Found
                                                </Dropdown.Menu> :
                                                    <Dropdown.Menu>
                                                        {
                                                            showQuery ? (
                                                                <div onClick={handleQueryPage}>

                                                                    {signalRQuery?.message}

                                                                </div>
                                                            ) : null
                                                        }
                                                    </Dropdown.Menu>
                                            }

                                        </Dropdown>

                                        : roles == "Manager" ?

                                            < Dropdown className='notification-message'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                    <icons.Bell className='bell-notify' onClick={handleNotificationClick} />
                                                    {
                                                        signalRNotification?.length === 0 || signalRNotification?.createdBy===isUser?.userId? null : <span class="badge-notification" onClick={handleNotificationClick}>

                                                            !
                                                        </span>
                                                    }

                                                </Dropdown.Toggle>
                                                {
                                                    signalRNotification?.length === 0 || signalRNotification?.createdBy===isUser?.userId? <Dropdown.Menu>
                                                        No Data Found
                                                    </Dropdown.Menu> :
                                                        <Dropdown.Menu>
                                                            {
                                                                showNotification ? (
                                                                    <div onClick={handleNotificationPageManager}>

                                                                        {signalRNotification?.message}

                                                                    </div>
                                                                ) : null
                                                            }
                                                        </Dropdown.Menu>
                                                }

                                            </Dropdown>

                                            :




                                            < Dropdown className='notification-message'>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className='notification-icon'>
                                                    
                                                    <icons.Bell className='bell-notify' onClick={handleNotificationClick} />
                                                    
                                                    {
                                                        auctionCreateData?.length === 0 || auctionCreateData == undefined ? "" : <span class="badge-notification" onClick={handleQueryClick}> {auctionCreateData?.length}
                                                        </span>
                                                    }

                                                    {
                                                        signalRNotification?.length === 0 || signalRNotification === undefined? "" : <span class="badge-notification" onClick={handleNotificationClick}>

                                                            {signalRNotification?.length}
                                                           
                                                           
                                                        </span>
                                                    }

                                                </Dropdown.Toggle>



                                                {
                                                    signalRNotification?.length === 0 && auctionCreateData?.length ===0 ? 
                                                    <Dropdown.Menu>
                                                        No Data Found 
                                                    </Dropdown.Menu> :
                                                        <Dropdown.Menu>
                                                            {
                                                                showNotification ? (
                                                                    <div onClick={handleNotificationPagePlayer}>

                                                               <Link to="/Player/Notification">{signalRNotification?.message}</Link>

                                                                    </div>
                                                                ) :   auctionCreateData?.map((item) => <p><Link to="/Player/AuctionPlayer">{item?.message.split("auction")[0]}</Link> auction  {item?.message.split("auction")[1]} </p>)
                                                            }

                                                  </Dropdown.Menu>


                                                }

                                                {/* {
                                                    auctionCreateData?.length == 0 || auctionCreateData == undefined ? <Dropdown.Menu>
                                                        No Data
                                                    </Dropdown.Menu> :
                                                        <Dropdown.Menu>

                                                            {auctionCreateData?.map((item) => <p><Link to="/Player/AuctionPlayer">{item?.message.split("auction")[0]}</Link> auction  {item?.message.split("auction")[1]} </p>)}
                                                        </Dropdown.Menu>
                                                } */}
                                            </Dropdown>
                                }

                                {isUser?.userImage === null ? <img src="/image/profileDefault.png" width="50" className='rounded-circle me-3'></img> : <img src={ImageUrl + isUser?.userImage} className='profile-image'></img>}
                                <Dropdown>
                                    {roles}
                                    <Dropdown.Toggle variant="none" className='d-flex align-items-center p-0'>

                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip1">

                                            {fullName}
                                        </Tooltip>}>
                                            <div className='text-hide'>
                                                {
                                                    isUser?.fullName === null ? <Loader /> : fullName
                                                }

                                            </div>
                                        </OverlayTrigger>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                        {roles == "Web Admin" ? <Dropdown>
                                            <Link to="/WebAdmin/profile" className='item-link'>
                                                Profile</Link>
                                        </Dropdown> : roles == "Super Admin" ? <Dropdown>
                                            <Link className='item-link' to="/SuperAdmin/Profile">
                                                Profile</Link>
                                        </Dropdown> : <Dropdown>
                                            <Link className='item-link' to="/Player/Profile">
                                                Profile</Link>
                                        </Dropdown>}

                                        {
                                            roles == "Web Admin" ? <Dropdown >
                                                <Link className='item-link' to="/WebAdmin/ChangePassword">
                                                    Change Password
                                                </Link></Dropdown>
                                                : roles == "Super Admin" ? <Dropdown >
                                                    <Link className='item-link' to="/SuperAdmin/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown> : <Dropdown >
                                                    <Link className='item-link' to="/Player/ChangePassword">
                                                        Change Password
                                                    </Link></Dropdown>
                                        }

                                        <Dropdown.Item onClick={logout} >
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </div >

        </>
    )
}
export default TopNavbar;


