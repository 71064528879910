import React, { useState, useEffect } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import { GetNotificationMes, NotificationReg } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as icons from 'react-bootstrap-icons';
import { ModalBox } from "../Outer/Modal";
import { ToastContainer, toast } from 'react-toastify';

const SuperNotification = () => {
  const [data, setData] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [itemLastData, setItemLastData] = useState([]);
  const [notificationMes, setNotificationMes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(10);
  const [validated, setValidated] = useState(false);
  const [activePage, setActivePage] = useState(1);
   const [showView, setShowView] = useState(false);
    const [count, setCount] = useState(0);
     const [notificationReg, setNotificationReg] = useState({
           message: "",
       })



 


  useEffect(() => {
    const NotificationMessageFun = async () => {
      await GetNotificationMes().then((response) => {
        setNotificationMes(response?.data?.dataList);
      });
    };
    NotificationMessageFun();
  }, [count]);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notificationMes?.slice(indexOfFirstNotification, indexOfLastNotification);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  }
  // const totalPages = Math.ceil(notificationMes.length / notificationsPerPage);
  const totalPages = Math.ceil((notificationMes?.length || 0) / notificationsPerPage);
  function convertUTCToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    return date.toString(); // Or use date.toLocaleString() for better format
}
  const sidebarfun = () => {
    setData(!data);
  };
  const viewFn = (viewItem) => {
    setShowView(true);
}

const handleClosed = () => {
  setShowView(false);
  setValidated(false);
}
const heandelChange = (e) => {
  setNotificationReg({
      ...notificationReg,
      [e.target.name]: e.target.value
  }
  );

}

// useEffect(() => {
//   // Create a new array to hold the concatenated first characters
//   const newItemData = [];
  
//   notificationMes?.forEach((item) => {
//     const user = item?.roleName.split(" ");
    
//     // Get the first character of the first word
//     const firstChar = user?.[0]?.charAt(0) || "";
//     // Get the first character of the second word if it exists
//     const secondChar = user?.[1]?.charAt(0) || "";
    
//     // Concatenate characters
//     if (firstChar) {
//       let combinedChars = firstChar; // Start with the first character

//       if (secondChar) {
//         combinedChars += secondChar; // Add the second character if it exists
//       }

//       // Add the combined characters to the array
//       newItemData.push(combinedChars);
//     }
//   });

//   // Set the state with the combined characters
//   setItemData(newItemData);
  
// }, [notificationMes]);

// console.log(itemData, "itemData");
 


   const handleSubmit = async (event) => {
          event.preventDefault();
          const form = event.currentTarget;
          if (notificationReg?.message == '') {
             return setValidated(true);
          }
          const registerData = {
              "message": notificationReg?.message
          }
          await NotificationReg(registerData).then((res) => {
              if (res) {
                  setCount(count + 1);
                  setShowView(false);
                  toast.success("Success");
                  setValidated(false);

              }
              else {
  
              }
          })
      };
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >
             <Row className='d-flex align-items-center'>
                        <Col md={6}><h1 className="heading-dash">Notification</h1></Col>
                        <Col md={6} className='text-right'><Button className='primary button-create-auction btn btn-primary ' onClick={() => viewFn()} ><icons.Plus class="icon-plus" />  Broadcast Massage</Button></Col>

                    </Row>
          <div className="group_data_table">

    
     

            <div className="notification_box">


              {currentNotifications?.map((ele, index) => {

                const localTime = convertUTCToLocalTime(ele?.createDate + "Z");
                const dayjsDate = dayjs(localTime);
                const formattedDate = dayjsDate.format("MM/DD/YYYY");
                const timePart = dayjsDate.format("hh:mm ss A");
                const user = ele?.roleName.split(" ");
      
                // Get the first character of the first word
                const firstChar = user?.[0]?.charAt(0) || "";
                // Get the first character of the second word if it exists
                const secondChar = user?.[1]?.charAt(0) || "";
           
                return (
                <>
                 <div className="notification p-2" key={ele.id}>
                  <div className="notification-right">
                    <div className="profile-pic2">
                    {firstChar}{secondChar}
                    </div>
                    <div className="ms-3 notification-area">
                      <h1>{ele?.roleName}</h1>
                      <div className="noti-msg">
                        {ele?.message}
                      </div>

                      <div className="Notification-Date">
                    {formattedDate}{timePart}
                     
                    </div>
                    </div>

                  </div>
                </div>
                </>
                );
              })}



              {/* Pagination Controls */}
              <div className="pagination notification-sec">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button className={`pagenation-item ${activePage === index + 1 ? 'active' : ''}`} key={index + 1} onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </button>
                ))}
              </div>

            </div>
          </div>
        </div>
        <div className={data ? "Footer" : "footer-content"}>
          <Footer />
        </div>
      </div>


      <ModalBox show={showView} title={<><h1 className="heading-dash">Add Broadcast Massage</h1></>} body={
                <>

                    <Row>

                        <Col>

                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            name="message"
                                            rows={3}
                                            onChange={heandelChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Message.
                                        </Form.Control.Feedback>

                                    </Form.Group>

                                </Row>
                                <Row>
                                    <Col md={6}></Col>
                                    <Col md={6} className='text-right'>

                                        <Button type="button" className='btn-Cancel secondary  me-3 mb-3' onClick={handleClosed} >Cancel</Button>
                                        <Button type="submit" className='btn-Save  mb-3'>Post</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>

                </>
            } />
    </>
  );
};

export default SuperNotification;


