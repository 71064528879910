import React, { useState, useEffect } from "react";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import DataTable from "react-data-table-component";
import { Footer } from "../../Footer";
import { Col, Row} from "react-bootstrap";
import { Table } from 'react-bootstrap';
import { SecondaryMarketAnnouncement } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";

export const Secondary_Market_AnnouncementP = () => {
    const [data, setData] = useState();
    const [searchText, setSearchText] = useState("");
    const [announcements, setAnnouncements] = useState([]);
    function sidebarfun() {
        setData(!data);
    }

    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toString();
    };

    const columns = [
        {
            name: <strong>Auction Name</strong>,
            selector: (row) => row.auctionName,
            sortable: true,
        },
        {
            name: <strong>Team Name</strong>,
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: <strong>Buyer</strong>,
            selector: (row) => row.teamOwnerName,
            sortable: true,
        },
      {
                   name: <strong>Start Date & Time</strong>,
                   selector: (row) => 
                     {
                                         // Ensure row?.endDate is defined
                                         if (!row?.startDate) return <p>Invalid date</p>;
                                          const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                                         const dayjsDate = dayjs(localTime);
                                         if (!dayjsDate.isValid()) {
                                             return <p>Invalid date</p>;
                                         }   
                                         const formattedDate = dayjsDate.format("MM/DD/YYYY");
                                         const timePart = dayjsDate.format("hh:mm ss A"); 
                                         return (
                                             <p>{formattedDate}, {timePart}</p>
                                         );
                                     },
                   sortable: true,
                 },
      
{
            name: <strong>End Date & Time</strong>,
            selector: (row) => 
              {
                                  // Ensure row?.endDate is defined
                                  if (!row?.endDate) return <p>Invalid date</p>;
                                   const localTime = convertUTCToLocalTime(row?.endDate + "Z");
                                  const dayjsDate = dayjs(localTime);
                                  if (!dayjsDate.isValid()) {
                                      return <p>Invalid date</p>;
                                  }   
                                  const formattedDate = dayjsDate.format("MM/DD/YYYY");
                                  const timePart = dayjsDate.format("hh:mm ss A"); 
                                  return (
                                      <p>{formattedDate}, {timePart}</p>
                                  );
                              },
            sortable: true,
          },
   
    

        {
            name: <strong>Winning Bid Amount</strong>,
            selector: (row) => row.bidAmount,
            sortable: true,
        },
        // {
        //     name: <strong>Level</strong>,
        //     selector: (row) => row.level,
        //     sortable: true,
        // },
        {
            name: < strong > Ownership Percentage %</strong>,
            selector: (row) => row.ownerShipPercentage,
            sortable: true,
        },

    ];

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const uniqueAuctionIds = new Set();

    useEffect(() => {
        const AnnouncementMsgFunc = async () => {
            await SecondaryMarketAnnouncement().then((response) => {
                setAnnouncements(response?.data?.data?.dataList);
            });
        };
        AnnouncementMsgFunc();
    }, []);

    const filteredDataList = announcements?.filter(item => {
        if (!uniqueAuctionIds.has(item?.auctionId)) {
            uniqueAuctionIds.add(item?.auctionId);
            return true;
        }
        return false;
    });

    const filteredData = searchText
        ? filteredDataList?.filter((item) => {
            return item?.auctionName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                item?.managerName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                item?.sportName?.toLowerCase().includes(searchText?.toLowerCase())
        })
        : filteredDataList;

    const filterData = (auctionId) => {
        return announcements.filter(item => item.auctionId === auctionId);
    };

    const ExpandedComponents = ({ data }) => {
        const relatedData = filterData(data.auctionId);

        return (
            <div>
                <h4 className=" mb-3">Details for  {data.auctionName}</h4>
                <Table striped bordered hover size="sm" className='text-center'>
                    <thead>
                        <tr>
                            <th>Auction Name</th>
                            <th>Team Name</th>
                            <th>Buyer</th>
                            <th>Start Date and Time</th>
                            <th>End Date and Time</th>
                            <th>Winning Bid Amount</th>
                            <th>Ownership Percentage % </th>

                        </tr>
                    </thead>
                    <tbody>

                        {relatedData.map((item) => {
                               const localTime = convertUTCToLocalTime(item?.startDate + "Z");
                                         const dayjsDate = dayjs(localTime);
                                         const formattedDate = dayjsDate.format("MM/DD/YYYY");
                                         const timePart = dayjsDate.format("hh:mm ss A");
                                         const localTime1 = convertUTCToLocalTime(item?.endDate + "Z");
                                         const dayjsDate1 = dayjs(localTime);
                                         const formattedDate1 = dayjsDate1.format("MM/DD/YYYY");
                                         const timePart1 = dayjsDate1.format("hh:mm ss A");
                            return (
                                <tr key={item?.auctionName}>
                                   <td>{item?.auctionName}</td>
                                    <td>{item?.teamName}</td>
                                    <td>{item?.teamOwnerName}</td>
                                    <td>{formattedDate}{timePart}</td>
                                  
                                    <td>{formattedDate1}{timePart1}</td>
                          
                                    <td>{item?.bidAmount}</td>
                        
                                    <td>{item?.ownerShipPercentage}</td>
                                </tr>
                            );
                        })}
                    </tbody>
               </Table>
            </div>
        );
    };

    return (
        <>
            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group">
                <Sidebar data={data} />
                <div
                    className={
                        data ? "dashboard-content full-contnet" : "dashboard-content"
                    }
                >
                    <Row>
                        <Col md={6}>
                            <h1 className="heading-dash">Secondary Market result</h1>
                        </Col>
                    </Row>

                    <div className="group_data_table smannouncement">
                        <div className="search-input d-flex align-items-center">
                            <label className="me-3">Search</label>
                            <input
                                type="text"
                                className="form-control mb-3 mt-3"
                                placeholder="Search..."
                                value={searchText}
                                onChange={handleSearch}
                            />
                        </div>

                        <DataTable
                            title="Announcement"
                            columns={columns}
                            data={filteredData}
                            pagination
                            expandableRows
                            expandableRowExpanded={(row) => row.defaultExpanded}
                            expandableRowsComponent={ExpandedComponents}
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30]}
                            paginationTotalRows={filteredData?.length}
                        />

                    </div>
                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    );
};


