import React, { useContext, useEffect, useId, useMemo, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Col, Button, Form, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ModalBox } from "../Outer/Modal";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import { CloseRounded } from "@mui/icons-material";
import { MultiSelect } from 'react-multi-select-component';
import {
  AuctionAll,
  SportAllMap,
  BidStatus,
  RefreshToken,
  SubscribeManager,
  ManagerGetAll,
  PlayerGetSubscribeManager,
  debounce
} from "../../redux/auth-reducer/auth-service";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";
import { ToastContainer, toast } from 'react-toastify';
import { Context } from "../../redux/Context";
import moment from "moment";

function AuctionPlayer() {
  const itemvalue =localStorage.getItem("abc");

  if(itemvalue==1){
    alert(itemvalue)
    window.location.reload();
    debugger
  
  }
  const [data, setData] = useState(false);
  const [searchTextClose, setSearchTextClose] = useState("");
  const [searchTextUpcomingLive, setSearchTextUpcomingLive] = useState("");
  const [searchTextUpcomingLiveAuction, setSearchTextUpcomingLiveAuction] = useState("");
  const [searchTextCloseAuction, setSearchTextCloseAuction] = useState("");
  const [showView, setShowView] = useState(false);
  const [itemlist, setitemList] = useState([]);
  const [itemsport, setitemSport] = useState([]);
  const [count, setCount] = useState(0);
  const [timestamp, setTimestamp] = useState();
  const [filterUpcomingLiveAuction, setFilterUpcomingLiveAuction] = useState([]);
  const [filterClosedAuction, setFilterClosedAuction] = useState([]);
  const [page, setpage] = useState(1)
  const [perPage, setPerPage] = useState(10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  // const [hubConnection, setHubConnection] = useState(null);
  const [signalRData, setSignalRData] = useState([]);
  const [BidStatusAuction, setBidStatusAuction] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subscribedManagers, SetSubscribedManagers] = useState([]);
  const [dataSingalR, setDataSingalR] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState([]);
  const { hubConnection } = useContext(Context);
 



  const isUser = JSON.parse(localStorage.getItem('isUser'));
  const userId = isUser?.userId;

  const navigate = useNavigate();
  const sidebarfun = () => {
    setData(!data);
  };

  useEffect(() => {
    async function apiCalling() {

      let temp_sports = await SportAllMap();
      setitemSport(temp_sports?.data?.data?.dataList);

    }
    apiCalling();
  }, []);

  // const BidAllFn = async (data) => {
   
  //   await BidStatus(data).then((response) => {
      
  //     console.log(response?.data?.data?.dataList, "dataList");
  //     setTotalPageSize(response?.data?.data?.totalPageSize)
  //     const selectedLabels = selectedOptions?.map(option => option.label).filter(label => label);

  //     const filteredData = response?.data?.data?.dataList?.length > 0 ? response?.data?.data?.dataList?.map((e) => {
  //       let remainingtimer = e.status == "Upcoming" ? new Date() : moment().add(e?.remainingBidTime.split(":")[0], 'minutes').add(e?.remainingBidTime.split(":")[1], 'seconds').format()
  //       return { ...e, recievedAt: remainingtimer, status: (e.status == "Live" && e.remainingBidTime == "00:00") ? "Closed" : e.status }
  //     })?.filter(item => {

  //       const match = selectedLabels?.includes(item.createdByName);

  //       return match;
  //     }) : [];

  //     setBidStatusAuction(filteredData);
  //     // setitemList(response?.data);

  //   })
  // }


  const BidAllFn = async (data) => {

    await BidStatus(data).then((response) => {
    
   
    
    setTotalPageSize(response?.data?.data?.totalPageSize);
    
    const selectedLabels = selectedOptions?.map(option => option.label).filter(label => label);
    
    
    const filteredData = response?.data?.data?.dataList?.length > 0 ? response?.data?.data?.dataList?.map((e) => {
    
    let remainingtimer = e.status == "Upcoming" ? new Date() : moment().add(e?.remainingBidTime.split(":")[0], 'minutes').add(e?.remainingBidTime.split(":")[1], 'seconds').format();
    
    return { ...e, recievedAt: remainingtimer, status: (e.status == "Live" && e.remainingBidTime == "00:00") ? "Closed" : e.status };
    
    })?.filter(item => {
    
    const match = selectedLabels?.includes(item.createdByName);
    
    return match;
    
    }) : [];
    
    
    // Change status from Upcoming to Live if any auctionId has Closed and Upcoming
    
    const auctionIds = new Set(filteredData.map(item => item.auctionId));
    
    auctionIds.forEach(auctionId => {
    
    const auctionItems = filteredData.filter(item => item.auctionId === auctionId);
    
    const hasClosed = auctionItems.some(item => item.status === "Closed");
    
    const hasUpcoming = auctionItems.some(item => item.status === "Upcoming");
    const hasLive = auctionItems.some(item => item.status === "Live");
    
    
    if (hasClosed && hasUpcoming && !hasLive) {
    
    const firstUpcomingIndex = auctionItems.findIndex(item => item.status === "Upcoming");
    
    if (firstUpcomingIndex !== -1) {
    
    auctionItems[firstUpcomingIndex].status = "Live";
    
    }
    
    }
    
    });
    
    
    setBidStatusAuction(filteredData);
    
    });
    
    }





  

  useEffect(() => {
    const getSubscribeManager = async () => {
      await PlayerGetSubscribeManager(userId).then((response) => {

        SetSubscribedManagers(response?.data)
      })
    }
    getSubscribeManager()
  }, []);

  // useEffect(() => {
  // const BidStatusAll = async () => {
  //   await AuctionAll().then((res) => {

  //     const selectedLabels = selectedOptions?.map(option => option.label).filter(label => label);

  //     const filteredData = res?.data?.length > 0 ? res?.data?.map((e) => {
  //       let remainingtimer = e.status == "Upcoming" ? new Date() : moment().add(e?.remainingBidTime.split(":")[0], 'minutes').add(e?.remainingBidTime.split(":")[1], 'seconds').format()
  //       return { ...e, recievedAt: remainingtimer, status: (e.status == "Live" && e.remainingBidTime == "00:00") ? "Closed" : e.status }
  //     })?.filter(item => {

  //       const match = selectedLabels?.includes(item.createdByName);

  //       return match;
  //     }) : [];

  //     setBidStatusAuction(filteredData);
  //   });
  // }
  // BidStatusAll()
  // }, [selectedOptions])



  useEffect(() => {
    const getAllManagers = async () => {
      await ManagerGetAll().then((res) => {

        setAllManager(res?.data?.data?.dataList);
      });
    }
    getAllManagers()
  }, [])

  const approvedManager = subscribedManagers?.filter(manager => manager.status === "Approved");

  const managerNames = approvedManager?.map(manager => ({
    label: manager?.managerName,
    value: manager?.managerId
  }));

  useEffect(() => {

    if (JSON.stringify(selectedOptions) !== JSON.stringify(managerNames)) {
      setSelectedOptions(managerNames);
    }
  }, [managerNames]);

  useEffect(() => {
    const data = {
      "PageNumber": page,
      "PageSize": perPage
    }
    BidAllFn(data);
    localStorage.setItem("abc",0);

  }, [selectedOptions, page]);


  var stDate, stTime;
  if (timestamp) {
    [stDate, stTime] = timestamp.split("T");
  } else {
    stDate = "";
    stTime = "";
  }

  function convertUTCToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    return date.toString(); // Or use date.toLocaleString() for better format
}

  function addLiveTeam(data) {
    if (!data) return
    if (data?.status != "Upcoming") return
    console.log("mmm Upcoming::")
    setBidStatusAuction((prev) => {
      let newData = prev.map((item) => {
        if (data.auctionId == item.auctionId && data.teamId == item.teamId && item.status != 'Live') {
          let remainingtimer = moment().add(item.bidTime, 'minutes').format()
          return { ...item, status: "Live", recievedAt: remainingtimer }
        }
        //  else if(data.auctionId == item.auctionId && data.teamId == item.teamId){
        //   return { ...item, status: "Closed" }
        //   // return { ...item }
        //  }
        return item
      })

      // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
      return newData
    })
  }

  function addLiveToNextTeam(data) {
    if (data.status != "Live") return
    console.log("mmm Live::")
    setBidStatusAuction((prev) => {
      let newData = prev.map((item) => {
        if (data.auctionId == item.auctionId && (data.teamId + 1) == item.teamId && item.status != 'Live') {
          let remainingtimer = moment().add(item.bidTime, 'minutes').format()
          return { ...item, status: "Live", recievedAt: remainingtimer }
        } else if (data.auctionId == item.auctionId && data.teamId == item.teamId) {
          return { ...item, status: "Closed" }
          // return { ...item }
        }
        return item
      })

      // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
      return newData
    })
  }


  function addZero(num) {

    if (num < 10) {
      return `0${num}`
    }
    return num

  }

  function getExectTime(end, nextItemStart) {
    let now = moment();
    var diffDays = nextItemStart.status == "Upcoming" ? ((new Date(nextItemStart.startDate + 'Z')).getTime() - (new Date()).getTime()) : ((new Date(end)).getTime() - (new Date()).getTime());
    var minutes = Math.abs(parseInt(diffDays / (1000 * 60)));
    let allAuctionBid = BidStatusAuction.filter((e) => e.auctionId == nextItemStart.auctionId).sort(function (a, b) { return a.teamId - b.teamId });
    let isAnyLive = allAuctionBid.some((e) => e.status == "Live")
    var seconds = parseInt(diffDays / 1000);


    if (!isAnyLive && nextItemStart.status == "Upcoming" && minutes == 0 && new Date(diffDays).getSeconds() == 0) {
      addLiveTeam(allAuctionBid[0])
    } else if (diffDays && minutes == 0 && new Date(diffDays).getSeconds() == 0 && nextItemStart.status != "Upcoming") {
      addLiveToNextTeam(nextItemStart)
    }
   return nextItemStart.status == "Live" ? `${minutes > 0 ? addZero(minutes) : "00"}:${new Date(diffDays).getSeconds() > 0 ? addZero(new Date(diffDays).getSeconds()) : "00"}` : ""
  }




 
  const customSort = (rowA, rowB) => {
    console.log("rowA", rowA)
    const a = rowA.title.toLowerCase();
    const b = rowB.title.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const columns = [
    {
      name: "Team Name",
      selector: (row) =>
        <>
          <OverlayTrigger
            overlay={<Tooltip >{row?.teamName}</Tooltip>}
            placement="top"
            delayShow={300}
            delayHide={150}
          >
            <p>{row?.teamName}</p>
          </OverlayTrigger>
        </>,

      sortable: true,
      // sortFunction : customSort
    },
    {
      name: "Auction Name",
      selector: (row) =>
        <>
          <OverlayTrigger
            overlay={<Tooltip >{row?.auctionName}</Tooltip>}
            placement="top"
            delayShow={300}
            delayHide={150}
          >
            <p>{row?.auctionName}</p>
          </OverlayTrigger>
        </>,
      sortable: true,
    },
    {
      name: "Contest Name",
      selector: (row) => row?.tournamentName,
      width: "200px",
      sortable: true,
    },
    {
      name: "Sport Name",
      selector: (row) => row?.sportName,
      sortable: true,
    },
   {
       name:"Start Date & Time",
       selector: (row) => 
         {
                             // Ensure row?.endDate is defined
                             if (!row?.startDate) return <p>Invalid date</p>;
                              const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                             const dayjsDate = dayjs(localTime);
                             if (!dayjsDate.isValid()) {
                                 return <p>Invalid date</p>;
                             }   
                             const formattedDate = dayjsDate.format("MM/DD/YYYY");
                             const timePart = dayjsDate.format("hh:mm ss A"); 
                             return (
                                 <p>{formattedDate}, {timePart}</p>
                             );
                         },
       sortable: true,
     },
    
    {
      name: "Bid Time Limit",
      selector: (row) => row?.bidTime,
      sortable: true,
    },
    {
      name: "Secondary Market",
      selector: (row) =>
        row.secondaryMarket ? (
          <div className="bg-succ">Available</div>
        ) : (
          <div className="bg-dang">Unavailable</div>
        ),
      width: "150px",
      sortable: true,
    },
    {
      name: "Starting Bid",
      selector: (row) => row?.startingBid,
      sortable: true,
    },
    {
      name: "Bid Increment",
      selector: (row) => row?.bidIncrement,
      sortable: true,
    },
    {
      name: "Bid Status",
      selector: (row) => {
        const statusColor = {
          Upcoming: "#f9c114",
          Live: "#043bc7",
          Closed: "red",
          Paused: "red",
          Play: "green",
          Archive: "red"
        };
        return (
          <span style={{ color: statusColor[row.status] }} >
            {row.status} {(row.status == "Live" || row.status == "Upcoming") ? getExectTime(row.recievedAt, row) : ""}
          </span>
        );
      },
      width: "130px",
      sortable: true,

    },
    {
      name: "Created By",
      selector: (row) =>
        <>
          <OverlayTrigger
            overlay={<Tooltip >{row?.createdByName}</Tooltip>}
            placement="top"
            delayShow={300}
            delayHide={150}
          >
            <p>{row?.createdByName}</p>
          </OverlayTrigger>
        </>,


      sortable: true,
    },

   
 


    


  
    {
      name: <div className="sticky-tab">Action </div>,
      selector: (row) => (
        <>

          {
            dataSingalR?.remainingBidTime == "00:00" ? <p>sdfd</p> : <div className="sticky-tab">
              {row?.status === "Live" ? (


                <button className="tablebtn placebid" 
                onClick={() => handleSubmit(row)}>
                  Place Bid
                </button>
              ) : (
                <button className="tablebtn placebid disable" disabled onClick={() => handleSubmit(row)}>
                  Place Bid
                </button>
              )}
            </div>
          }

        </>
      ),
      sortable: true,
      cellClass: 'fixed-column', // Add this class for styling
    },

  ];





  // Function to apply the fixed-row class to the last row
  const conditionalRowClassName = (row, rowIndex) => {
    return rowIndex === filteredDataUpcomingLiveAuction?.length - 1 ? 'fixed-row' : '';
  };

  const handleSubmit = (item) => {
    navigate(`/Player/PlaceBid?auctionId`, { state: item });
  };

  useEffect(() => {
    if (isButtonDisabled) {
      setIsButtonDisabled(true);
    }
  }, [isButtonDisabled])

  const handleClosed = () => {
    setShowView(false);
  };
  // console.log(dataSingalR,"dataSingalR")
  // {dataSingalR?.map((item)=>{console.log(item.remainingBidTime,"test data")}}

  const handleSearchClose = (e) => {
    setSearchTextClose(e.target.value);
  };
  const handleSearchUpcomingLive = (e) => {
    setSearchTextUpcomingLive(e.target.value);
  };

  const handleSearchCloseAuction = (e) => {
    setSearchTextCloseAuction(e.target.value);
  };
  const handleSearchUpcomingLiveAuction = (e) => {
    setSearchTextUpcomingLiveAuction(e.target.value);
  };



  /// new Code

  const filterListData = useMemo(() => {
    console.log({ BidStatusAuction })
    let allList = BidStatusAuction?.length > 0 ? BidStatusAuction?.filter((item) => {
      return searchTextUpcomingLive?.length > 0 ? item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) : item

    }) : []

    let liveOnTop = allList.filter((event) => event.status === "Live")
    let otherLeft = allList.filter((event) => event.status !== "Live")
    return [...liveOnTop, ...otherLeft]

  }, [BidStatusAuction, searchTextUpcomingLive])





  // const filterListData = useMemo(() => {
  //   console.log({BidStatusAuction})
  //   return BidStatusAuction.filter((item) => {

  //     return searchTextUpcomingLive?.length > 0 ? item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()): item

  //   })
  //     },[BidStatusAuction, searchTextUpcomingLive])





  // useEffect(() => {

  //   const filterUpcomingsLivesAuction = (eventArray) => {
  //     console.log("eventArray",)
  //     let filterList = eventArray?.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused");
  //     console.log("filterList", filterList)
  //     let onlyLiveEvent = filterList?.filter((e) => e.status === "Live")
  //     let notOnlyLiveEvent = filterList?.filter((e) => e.status !== "Live")
  //     return [...onlyLiveEvent, ...notOnlyLiveEvent]
  //   };

  //   setFilterUpcomingLiveAuction(filterUpcomingsLivesAuction(BidStatusAuction));

  //   const filterClosesAuction = (eventArray) => {

  //     return eventArray?.filter((event) => event.status === "Closed" || event.status === "Archive");
  //   };

  //   setFilterClosedAuction(filterClosesAuction(BidStatusAuction));

  // }, [signalRData, BidStatusAuction]);



  const filteredDataUpcomingLiveAuction = searchTextUpcomingLive ? filterUpcomingLiveAuction.filter((item) => {
    return item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase())

  }) : filterUpcomingLiveAuction;


  const filteredDataCloseAuction = searchTextClose ? filterClosedAuction.filter((item) => {
    return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
  }) : filterClosedAuction;



  // useEffect(() => {
  //   if (signalRData && BidStatusAuction) {
  //     const updatedAuctionData = BidStatusAuction?.map((auction) => {
  //       const matchingSignalRAuction = signalRData?.find((signalRAuction) => signalRAuction?.auctionId === auction?.auctionId && signalRAuction?.teamId === auction?.teamId);
  //       if (matchingSignalRAuction) {
  //         auction.currentBidAmount = matchingSignalRAuction?.currentBidAmount;
  //         auction.startingBid = matchingSignalRAuction?.startingBid;
  //         auction.status = matchingSignalRAuction?.status;
  //       }
  //       return auction;
  //     });
  //     setBidStatusAuction(updatedAuctionData);
  //   }


  // }, [signalRData]);

  var tempTimeOut = false

  useEffect(() => {
    let isListLive = [...BidStatusAuction];

    clearInterval(tempTimeOut)
    if (isListLive?.length > 0) {
      var num = (new Date()).getTime();
      let now = moment().utc();
      tempTimeOut = setInterval(() => {
             let remaingData = isListLive.sort(function (a, b) { return a.teamId - b.teamId })
          .sort(function (a, b) { return new Date(a.startDate) - new Date(b.startDate) })
          .filter(function (a) {
            // now.isAfter(a.startDate) && 
            if (a.status == "Upcoming") {
              return { ...a, recievedAt: new Date() }
            } else if (now.isAfter(a.startDate) && a.status == "Live") {
              let remainingtimer = moment().add(a?.remainingBidTime.split(":")[0], 'minutes').add(a?.remainingBidTime.split(":")[1], 'seconds').format()
              return { ...a, recievedAt: remainingtimer}
            }
            return a
            // return (num - new Date(a.startDate + 'Z').getTime()) < 0; })
          })
   
        setBidStatusAuction(remaingData)

        // let liveFound = remaingData?.filter((event) => event.status === "Live");

        // liveFound

      }, 1000)
    }

    return () => {
      clearInterval(tempTimeOut)
    }
  }, [BidStatusAuction])




  // var tempTimeOut = false

  // // useEffect(() => {

  //   function updateUI(data) {

  //     // write Code to update UI / auction status
  //   console.log('write Code to update UI', data);

  //   setBidStatusAuction((prev) => {
  //         let newData =  prev.map((item) => {

  //            if(data.auctionId == item.auctionId && data.teamId == item.teamId){
  //             return { ...item, status: "Live" }
  //            }
  //            return item
  //         })

  //         return newData
  //       })
  //   }

  //   function setTimer(TimerData) {

  //     clearTimeout(tempTimeOut);

  //     var num = (new Date()).getTime();

  //     var tempData = TimerData.sort(function (a, b) { return a.teamId - b.teamId }).sort(function (a, b) { return new Date(a.startDate) - new Date(b.startDate) }).filter(function (a) { return (num - new Date(a.startDate + 'Z').getTime()) < 30000 && (num - new Date(a.startDate + 'Z').getTime()) > 0; })



  //     if (tempData.length > 0) {
  //       var interval = (num - new Date(tempData[0]?.startDate + 'Z').getTime());
  //       console.log("tempData", tempData, interval)
  //       tempTimeOut = setTimeout(updateUI, interval, tempData?.[0]);
  //     }
  //   }




  //   return () => {
  //     clearTimeout(tempTimeOut)
  //   }
  // }, [BidStatusAuction])













  useEffect(() => {
    console.log(hubConnection, "hubConection")
    if (hubConnection) {
      // Listen for messages from the SignalR hub

      hubConnection.on("ReceiveBidStatus", (data) => {
        console.log("Received Bid Statuses:", data);



        if (data.filter((e) => e.status != "Closed")?.length > 0) {

          setBidStatusAuction((prev) => {
            let newData = prev.map((item) => {

              let isLiveNow = data.filter((e) => e.auctionId == item.auctionId && e.teamId == item.teamId && e.status == 'Live');

              let isPresentList = data.filter((e) => e.auctionId == item.auctionId && e.teamId == item.teamId)
              // console.log(item, "isLiveNow")

              if (isLiveNow?.length > 0) {
                let remainingtimer = moment().add(isLiveNow[0]?.remainingBidTime.split(":")[0], 'minutes').add(isLiveNow[0]?.remainingBidTime.split(":")[1], 'seconds').format()
                return { ...item, status: (isLiveNow[0].status == "Live" && isLiveNow[0].remainingBidTime == "00:00") ? "Closed" : "Live", totalPoolAmount: isLiveNow[0]?.totalPoolAmount, recievedAt: remainingtimer }
              } else if (isPresentList[0]?.status == "Paused") {
                return { ...item, status: "Paused" }
              } else if (item.status == "Live") {
                return { ...item, status: "Closed", recievedAt: new Date() }
              } else if (isPresentList?.length > 0) {
                return { ...item, status: isPresentList[0].status, totalPoolAmount: isLiveNow[0]?.totalPoolAmount }
              }
              return { ...item, recievedAt: new Date() }
            })
            // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
            return newData
          })

        }


        // setSignalRData(data);
      });


      hubConnection.on("ReceiveCurrentBid", (data) => {

        console.log("ReceiveCurrentBid", data);



        // setCurrentTotalPlaceBId(data)
        data.map((oldRest) => {
          console.log(data, "datatatta")
          setBidStatusAuction((prev) => {
            let newData = prev.map((item) => {
              if (oldRest.auctionId == item.auctionId && oldRest.teamId == item.teamId && oldRest.status == 'Live') {
                let remainingtimer = moment().add(oldRest?.remainingBidTime.split(":")[0], 'minutes').add(oldRest?.remainingBidTime.split(":")[1], 'seconds').format()
                return { ...item, recievedAt: remainingtimer }
              }
              return item
            })
            return newData
          })

        })
      });


      hubConnection.on("GetRecentAuction", (data) => {
        console.log("GetRecentAuction:::", data);

        let newData = data.map((e) => {
          return { ...e, recievedAt: new Date() }
        })

        setBidStatusAuction((prev) => [...newData, ...prev])

      });



      // Cleanup the event listener on unmount
      return () => {
        hubConnection.off("ReceiveBidStatus");
        hubConnection.off("GetRecentAuction");
        hubConnection.off("ReceiveCurrentBid");
        console.log("off conne: ", hubConnection);
      };
    }
  }, [hubConnection]);


  console.log(BidStatusAuction,"GetRecentAuction data")







  





  







  const options = allManager?.map(manager => ({
    label: `${manager?.firstName} ${manager?.lastName}`,
    value: manager?.managerId
  }));

  const handleRequest = async (managerId) => {
    await SubscribeManager(userId, managerId).then((res) => {

      if (res?.data?.status == true == true) {
        toast.success(res.data?.message);
      } else {
        toast.error(res.data?.message);
      }
    });
  };

  const handleChange = (selected) => {

    const addedOptions = selected?.filter(option => !selectedOptions?.some(selectedOption => selectedOption?.value === option?.value));
    const removedOptions = selectedOptions?.filter(option => !selected?.some(selectedOption => selectedOption?.value === option?.value));

    setSelectedOptions(selected);

    addedOptions.forEach(option => {
      handleRequest(option.value);
    });

    removedOptions?.forEach(option => {

      handleRequest(option.value);
    });
  };

  useEffect(() => {
    const nextPageClick = document.getElementById('pagination-next-page');

    nextPageClick?.addEventListener('click', debounce((event) => {
      console.log("pageing", event)
      setpage((e) => e + 1)
    }), 500)

    return () => {
      nextPageClick?.removeEventListener('click', () => console.log("clear"))
    }
  }, [filterListData])






  // console.log(signalRData, "SIGNALr");
  // console.log(dataSingalR, "data???????????????")

  return (
    <>


      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >


          <Row className="select-dropdown-manager">

            <Col md={6} ><h1 className="heading-dash">Auction</h1></Col>
            <Col md={6}  >

            </Col>
          </Row>

          <div className="group_data_table tabs-auction ">

            <div className="tabs-box">

              {
                managerNames?.length == 0 ? (<>
                  <h4>Add Manager To See Auctions</h4>

                  <Form.Group as={Col} md="4 mt-3" lg="2" className="text-left mt-2" controlId="validationCustomOption">
                    <Form.Label>Select Manager</Form.Label>
                    <MultiSelect
                      options={options || []}
                      value={selectedOptions || []}
                      onChange={handleChange}
                      labelledBy={"Select"}
                      required
                    />
                  </Form.Group>
                </>
                ) : (
                  <>
                    <Row>

                      <Col lg="2" md={4} className="dropdown-manager-approve">
                        <Form.Group controlId="validationCustomOption">
                          <Form.Label>Select Manager</Form.Label>
                          <MultiSelect
                            options={options || []}
                            value={selectedOptions || []}
                            onChange={handleChange}
                            labelledBy={"Select"}
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Tabs
                      defaultActiveKey="profile"
                      id="auctiontabs"
                      className="mb-0 tab-list-item player"
                      justify
                    >

                      <Tab eventKey="profile" title="Live and Upcoming">
                        <div>
                          <div className="auctiontabs auction-closed-status">

                            <div className="search-input filter auction-filter palyer">

                              <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                                <Form.Label className="search-player-auction">Search</Form.Label>
                                <input
                                  type="text"
                                  className="form-control mb-3 mt-3"
                                  placeholder="Search..."
                                  value={searchTextUpcomingLive}
                                  onChange={handleSearchUpcomingLive}
                                />
                              </Form.Group>
                            </div>


                            <DataTable
                              columns={columns}
                              data={filterListData?.filter((event) => event.status === "Live" || event.status === "Upcoming" || event.status === "Paused")}
                              getRowProps={(row) => ({
                                className: row.status === "Live" ? 'row-live' : 'row-disabled', // Add row class based on status
                              })}
                              pagination
                              paginationPerPage={10}
                              // defaultSortFieldId={1}
                              paginationRowsPerPageOptions={[10, 20, 30]}
                              style={{ overflowX: 'auto' }} // Ensure horizontal scrolling
                            // sortFunction={customSort}
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Closed" title="Closed">
                        <div>
                          <div className="auctiontabs auction-closed-status">
                            <div className="search-input filter auction-filter ">
                              <input
                                type="text"
                                className="form-control mb-3 mt-3"
                                placeholder="Search..."
                                value={searchTextUpcomingLive}
                                onChange={handleSearchUpcomingLive}
                              />
                            </div>

                            <DataTable
                              columns={columns}
                              data={filterListData?.filter((event) => event.status === "Closed" || event.status === "Archive")}
                              pagination
                              // paginationPerPage={10}
                              // defaultSortFieldId={1}
                              paginationRowsPerPageOptions={[10, 20, 30]}
                              // sortFunction={customSort}

                              onRowClicked={(e) => console.log("page", e)}
                              paginationTotalRows={totalPageSize * 10}

                            />
                          </div>
                        </div>

                      </Tab>

                    </Tabs>
                  </>
                )

              }

            </div>

          </div>

        </div>
        <Footer />
      </div>

      <ModalBox
        show={showView}
        title={
          <>
            <h1 className="heading-dash">Team Detail</h1>
          </>
        }
        body={
          <>
            <Table bordered hover className="table-team border">
              <thead>
                <tr>
                  <th>Logo</th>
                  <th>Team Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    <Image src="/image/team-icon.png" width={50} />
                  </td>
                  <td>Team 1</td>
                </tr>
              </tbody>
            </Table>
          </>
        }
        footer={
          <>
            <Button variant="secondary close-btn" onClick={handleClosed}>
              <CloseRounded />
            </Button>
          </>
        }
      />
    </>
  );
}

export default AuctionPlayer;

