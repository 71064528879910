import React, { useState } from 'react'
import Headertwo from './Headertwo'
import Footertwo from './Footertwo'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import * as icons from 'react-bootstrap-icons';
import { ContactUsAPI } from '../../redux/auth-reducer/auth-service';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export const ContactUs = () => {
  const [validated, setValidated] = useState();
  const [messageEamil, setMessageEamil] = useState(false);
  const navigate = useNavigate()
  const [message, setMessage] = useState('');
  const [regForm, setRegForm] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    message: ""
  });

  const handelForm = (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    });
 
    
  }


  const maxWords = 1024;
  const handelMessage =(e) =>{
    setRegForm({
      ...regForm,
      message: e.target.value,
    });
    const { value } = e.target;
    const wordCount = value.trim().length;
    console.log(wordCount,"dfsdfssfd")
    if (wordCount <= maxWords) {
      setMessage(value);
    } else {

      alert(`You can only enter a maximum of ${maxWords} words.`);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidated(true);

    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

    if (!regForm?.emailId) {
      setMessageEamil("Please enter an email.");
      return;
    }


    if (!regForm?.emailId.match(mailformat)) {
      setMessageEamil("Email is not valid.");
      return;
    }

    setMessageEamil("");

    const data = [
      {
        "firstName": regForm?.firstName,
        "lastName": regForm?.lastName,
        "emailId": regForm?.emailId,
        "message": regForm?.message,
        "createDate": new Date(),
      }
    ];

    try {
      const res = await ContactUsAPI(data);
      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        console.log(res,"success")
          navigate("/Home");
      } else {
        console.log(res,"err")
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }

    
  };

  return (
    <>
      <div className='group-section'>
        <Headertwo />
        <div className='banner-item-contact-us'>

        </div>
      </div>

      <section className='contact-area'>
        <Container>
          <Row>
            <Col md="6" className='pe-0 Get-contact'>
              <div >
                <h2 className='mb-4'>Get in Touch</h2>
                <div className="d-flex mb-5">
                  <div class="me-4 text-primary">
                    <icons.Envelope className='icon-contact' />
                  </div>
                  <div>
                    <h4 className="mb-2">Chat to us</h4>
                    <address className="mb-0 text-content-contact">Our friendly team is here to help.</address>
                    
                    <a href="mailto:support@xcutta.com" className="ms-0 mt-2 text-area">support@xcutta.com</a>
                  </div>
                </div>

                <div className="d-flex mb-5">
                  <div class="me-4 text-primary">
                    <icons.GeoAlt className='icon-contact' />
                  </div>
                  <div>
                    <h4 className="mb-2">Office</h4>
                    <address className="mb-0 text-content-contact">Xcutta Corporate Office, P.O. Box 191, Thomaston, GA 30286</address>
                  </div>
                </div>

                <div className="u-social-icons u-spacing-17 u-social-icons-1">

                  <a class="u-social-url" title="twitter" target="_blank" href="https://www.x.com/xcutta2024">
                    <span className="u-icon u-social-facebook u-social-icon u-text-white">
                      <icons.Twitter />
                    </span>
                  </a>
                  <a className="u-social-url" title="Instagram" target="_blank" href="https://www.instagram.com/xcutta/">
                    <span className="u-icon u-social-facebook u-social-icon u-text-white">
                      <icons.Instagram />
                    </span>
                  </a>
                  <a className="u-social-url" title="Tiktok" target="_blank" href="https://www.tiktok.com/@xcutta1">
                    <span className="u-icon u-social-facebook u-social-icon u-text-white">
                      <icons.Tiktok />
                    </span>
                  </a>

                </div>
              </div>
            </Col>
            <Col md="6" className='bg-white cont-form'>
              <h2>Contact Us</h2>
              <Form
                noValidate
                validated={validated}
                className="registration-contact"
                onSubmit={handleSubmit}
              >
                <Form.Group

                  controlId="validationCustomFristname"
                  className='mb-3'
                >
                  <Form.Label>First Name</Form.Label>{" "}
                  <span
                    style={{
                      color: "#f44336",
                      fontSize: "20px",
                      marginLeft: "-1px",
                    }}
                  >
                    *
                  </span>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"

                      name="firstName"
                      onChange={handelForm}
                      placeholder="Enter your first name"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter a Frist name.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group

                  controlId="validationCustomFristname"
                  className='mb-3'
                >
                  <Form.Label>Last Name</Form.Label>{" "}
                 
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"

                      name="lastName"
                      onChange={handelForm}
                      placeholder="Enter your last name"
                      aria-describedby="inputGroupPrepend"

                    />
                   
                  </InputGroup>
                </Form.Group>
                <Form.Group className="text-left mb-3">
                  <Form.Label>Email</Form.Label>
                  <span
                    style={{
                      color: "#f44336",
                      fontSize: "20px",
                      marginLeft: "4px",
                    }}
                  >
                    *
                  </span>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      name="emailId"
                      maxLength="50"
                      onChange={handelForm}
                      placeholder="Enter your email"
                      autoComplete="off"
                      required
                    />

                    {
                      messageEamil && <Form.Control.Feedback type="invalid">{messageEamil}</Form.Control.Feedback>
                    }
                  </InputGroup>
                </Form.Group>


                <Form.Group controlId="message" className="mb-3">
                  <Form.Label>
                    Message (Max 1024 characters)<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={message}
                    onChange={handelMessage}
                    name="message"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a message.
                  </Form.Control.Feedback>
                  <div>
                    {message.length} / {maxWords} characters
                  </div>
                </Form.Group>
                <Col md="12 submit_button">
                  <Button type="submit" className="btn-warning w-100 mt-5">
                    Submit
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      <Footertwo />
    </>
  )
}
