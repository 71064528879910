import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { SportAllMap, TeamAll, TeamGetBy, TournamentRegApi, UploadImage, getRecentTournament } from '../../redux/auth-reducer/auth-service';
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Footer } from '../../Footer';
import { DatePicker, Loader } from 'rsuite';
import { ImageUrl } from '../../utils/config/config';

export const CreateTournament = () => {
  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsFeild, setSelectedOptionsFeild] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedSport, setSelectedSport] = useState([]);
  const [error, setError] = useState('');
  const [tournamentImage, setTournamentImage] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataImage, setDataImage] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sportIdData, setSportIdData] = useState();
  const [tournamentIdList, setTournamentIdList] = useState();
  const [selectedSportId, setSelectedSportId] = useState([]);
  const [errorSt, setErrorSt] = useState(false);
  const [errorEnd, setErrorEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regularTeam, setRegularTeam] = useState([]);
  const [nonTeamFeild, setnonTeamFeild] = useState([]);
  const [selectedFieldTeamValue, setSelectedFieldTeamValue] = useState(null);

  function sidebarfun() {
    setData(!data)
  }
  const navigate = useNavigate();
  const [tournamentReg, setTournamentReg] = useState({
    tournamentName: "",
    tournamentImage: null,
    startDate: "",
    endDate: "",
    sportId: "",
    teamId: "",
    isField:[]
  })

  useEffect(() => {
    const TeamGet = async () => {
      await TeamAll().then((res) => {

        setSelectedTeam(res?.data);

      })
    }
    TeamGet()
  }, [])

  useEffect(() => {
    const SportGet = async () => {
      await SportAllMap().then((res) => {

        setSelectedSport(res?.data?.data?.dataList);
      })
    }
    SportGet()
  }, [])



  useEffect(() => {
    var listItem = selectedSportId ? selectedSportId.map((list) => {
      return { label: list?.teamName, value: list?.teamId }
    }) : [];
    var options = [
      ...listItem,
    ];
    setRegularTeam(options)
  }, [selectedSportId]);





  useEffect(() => {
    var listItemFeild = selectedSportId ? selectedSportId.map((list) => {
      return { label: list?.teamName, value: list?.teamId }
    }) : [];
    var optionsFeild = [
      ...listItemFeild,
    ];
    setnonTeamFeild(optionsFeild)
  }, [selectedSportId]);






  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
   
    if (tournamentReg?.startDate == '') {
      setErrorSt(true)
    }
    else {

    }
    if (tournamentReg?.endDate == '') {
      setErrorEnd(true);
    }
    else {

    }
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      var fK_TeamId = tournamentReg.fK_TeamId.map((ele) => {
        return ele.value;
      })
      var teamid = fK_TeamId;
    }

 
    var FieldTeam =selectedOptions?.map((item)=>{
      if(item?.value){
        return 0
      }
    })

   var feildTeamR= selectedOptionsFeild?.map((item)=>{
    if(item?.disabled===false){
      return 1
    }
   })

   var feildTeamRId= selectedOptionsFeild?.map((item)=>{
         return item?.value
   })
console.log(feildTeamRId,"feildTeamRId")

   var combinedArray = [...FieldTeam, ...feildTeamR];
   var combinedArray2 = [...teamid, ...feildTeamRId];

    if (tournamentReg.startDate < new Date()) {
      toast.error('Your selected start date and time has already expired. Please select a different time.');
    } else {
      const data = [{
        "crudOperationId": true,
        "tournamentId": 0,
        "tournamentName": tournamentReg?.tournamentName,
        "tournamentImage": dataImage || null,
        "startDate": tournamentReg?.startDate,
        "endDate": tournamentReg?.endDate,
        "sportId": Number(sportIdData),
        "teamId": combinedArray2,
        "isField":combinedArray,
      }]



      await TournamentRegApi(data).then((res) => {
        if (res?.data?.status == true) {
          setErrorEnd(false)
          setErrorSt(false);
          setLoading(false)
            navigate('/SuperAdmin/Contest');
          toast.success(res?.data?.message);
          GetRecentData();
        }
        else {
          toast.error(res?.data?.message);
        }
      })
    }

    if (selectedOptions.length > 0) {

      setError('')
    }
    else {
      setError('Please select atleast two teams for the Contest.');
    }
    return true;

  }

  const GetRecentData = async () => {
    await getRecentTournament().then((res) => {

      setTournamentIdList(res[0]);
    });
  };


const handelselect = (e) => {
  setSelectedOptions(e);
  setTournamentReg({
      ...tournamentReg,
      fK_TeamId: e
  });
  setError('');
  const selectedValues = e.map(option => option.value);
    const newNonTeamOptions = nonTeamFeild.map(option => ({
      ...option,
      disabled: selectedValues.includes(option.value)
  }));
  setnonTeamFeild(newNonTeamOptions);

  const selectedFieldValues = selectedOptionsFeild.map(option => option.value);
  const newRegularOptions = regularTeam.map(option => ({
      ...option,
      disabled: selectedFieldValues.includes(option.value) 
  }));
  setRegularTeam(newRegularOptions);
};

const handelselectFeild = (selected) => {
  setSelectedOptionsFeild(selected);
  setError('');
  const selectedFieldValues = selected.map(option => option.value);
  setSelectedFieldTeamValue(selectedFieldValues?.length > 0 ? selectedFieldValues[0] : null); // Store the first selected value

  const newRegularOptions = regularTeam.map(option => ({
      ...option,
      disabled: selectedFieldValues.includes(option.value)
  }));
  setRegularTeam(newRegularOptions);
  const selectedRegularValues = selectedOptions.map(option => option.value);
  const newNonTeamOptions = nonTeamFeild.map(option => ({
      ...option,
      disabled: selectedRegularValues.includes(option.value) 
  }));
  setnonTeamFeild(newNonTeamOptions);
};


console.log(selectedOptions,"sdf")


  const handelFrom = (e) => {
    setTournamentReg({
      ...tournamentReg,
      [e.target.name]: e.target.value
    }
    )
  }

  const handelSportByTeam = (e) => {
    setSportIdData(e.target.value);
    setTournamentReg({
      ...tournamentReg,
      [e.target.sportId]: e.target.value
    }
    )

  }

  useEffect(() => {
    const SportGetTeam = async () => {
      await TeamGetBy(sportIdData).then((res) => {
        setSelectedSportId(res);
      });
    };
    SportGetTeam();
  }, [sportIdData]);

  // useEffect(() => {
  //   const SportGetTeam = async () => {
  //     await TeamGetBy(sportIdData).then((res) => {
  //       setSelectedSportId(res?.data);
  //     });
  //   };
  //   SportGetTeam();
  // }, []);


  const handelImage = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    setTournamentImage(e.target.files[0].name)
    const dataList = (e.target.files[0]);

    const imageAPI = async () => {
      const formDataImage = new FormData();
      formDataImage.append('file', dataList);
      await UploadImage(formDataImage).then((res) => {
        if (res?.data?.status == true) {
          setDataImage(res?.data?.message);

        } else {
          toast.error(res?.message);

        }
      })
    }

    imageAPI()
  }
  const cancelbtn = (e) => {
    navigate(-1)
  }

  const handleStartDateChange = (date) => {
    setTournamentReg({
      ...tournamentReg,
      startDate: date
    })
    setStartDate(date);
    setErrorSt(false)
  };

  const handleEndDateChange = (date) => {
    setTournamentReg({
      ...tournamentReg,
      endDate: date
    }
    )
    setEndDate(date);
    setErrorEnd(false)

  };
  const disabledDate = (date) => {
    return date < startDate;
  };

  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <Row>
            <Col md={6}><h1 className="heading-dash">Create Contest</h1></Col>
          </Row>
          <Card className='ps-3 pe-3 pb-3 mt-3'>
            <Form noValidate validated={validated} className='registration_tournament' onSubmit={handleSubmit}>
              <Row>

                <Form.Group as={Col} md="12 mt-3" controlId="validationCustomFristname">

                  <Form.Label className="mt-3">Contest Name</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="tournamentName"
                      maxLength={50}
                      onChange={handelFrom}
                      placeholder="Enter your Contest Name"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter a Contest Name.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6 mt-3" className="text-left " controlId="validationCustomCountry">
                  <Form.Label>Sports</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Select name="sportId" onChange={handelSportByTeam} className='select-option select-sport' required as="select">
                      <option value={''}>Select Sports</option>
                      {selectedSport?.map((listItem) =>
                        <option key={listItem.sportID} value={listItem?.sportId}>{listItem?.sportName}</option>
                      )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      Please a select sport name.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                  <Form.Label>Logo Image (Optional)</Form.Label>
                  <Form.Control
                    type="file"
                    name="tournamentImage"
                    onChange={handelImage}
                    placeholder="Choose file"
                  />

                  <p className='mt-2 image-upload-mess'>(jpeg, jpg, png and size upto 2 mb)<span className='text-danger'>*</span></p>
                  {loading && <Loader />}
                 {dataImage ? <img src={ImageUrl + dataImage} alt="Selected" width={100} /> : ""}
                </Form.Group>

                <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    placeholder="Start Date"
                    format="MM/dd/yyyy HH:mm aa"
                    value={startDate}
                    name="startDate"
                    onChange={handleStartDateChange}
                    disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                    required
                  />

                  <Form.Control.Feedback type="invalid">
                    Please a choose file.
                  </Form.Control.Feedback>

                  {errorSt ? <div style={{ color: '#dc3545' }}>Please select start date.</div> : ""}
                </Form.Group>
                <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    placeholder="End Date"
                    format="MM/dd/yyyy HH:mm aa"
                    value={endDate}
                    name="endDate"
                    onChange={handleEndDateChange}
                    disabledDate={disabledDate}
                    required
                  />

                  <Form.Control.Feedback type="invalid">
                    Please enter end date.
                  </Form.Control.Feedback>
                  {errorEnd ? <div style={{ color: '#dc3545' }}>Please select end date.</div> : ""}
                </Form.Group>

                <Form.Group as={Col} md="6 mt-3" className="text-left mt-2" controlId="validationCustomOption">
                <Form.Label>Regular Teams</Form.Label>
                <MultiSelect
                    options={regularTeam}
                    value={selectedOptions}
                    onChange={handelselect}
                    labelledBy={"Select"}
                    required
                />
                <Form.Control.Feedback type="invalid">
                    Please select a team.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6 mt-3" className="text-left mt-2" controlId="validationCustomOptionField">
                <Form.Label>Field Teams</Form.Label>
                <MultiSelect
                    options={nonTeamFeild}
                    value={selectedOptionsFeild}
                    onChange={handelselectFeild}
                    labelledBy={"Select"}
                />
            </Form.Group>

           


                <Col className='text-right mt-3'>

                  <button type="button" className='cancel-btn me-3 mt-3' onClick={cancelbtn} >Cancel</button>
                  <Button type="submit" className='update-btn-prof'>Save</Button>
                </Col>
              </Row>
            </Form>

          </Card>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}


